<template>
  <a :href="href" class="navbar-link">{{ text }}</a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavBarLink",
  props: ["href", "text"],
});
</script>

<style lang="postcss">
.navbar-link {
  @apply px-3 py-1 ml-4 text-sm font-medium leading-5 transition duration-150 ease-in-out rounded-md text-blueGray-500 hover:bg-blueGray-100 hover:text-blueGray-600 focus:outline-none focus:bg-gray-200;
}
</style>
