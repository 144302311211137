<template>
  <div class="flex justify-center items-center">
    <form class="flex justify-center items-center form-control w-7/8">
      <div class="flex relative container justify-center">
        <div class="grid justify-items-stretch container w-3/4">
          <!-- border-b-2: isHasOptions ? true : false-->
          <!-- rounded-b-none: isHasOption ? true : false-->
          <input
            type="text"
            placeholder="Bangumi Subtitles here"
            class="
              w-full
              pl-10
              z-5
              input input-primary
              border-2 border-purple-700
              focus:ring-0
              rounded-r-none
              border-b-2
              rounded-b-none
              border-b-0
            "
          />
          <div
            class="
              relative
              pt-3
              border-2
              bg-white
              rounded-b-lg rounded-t-none
              border-purple-700 border-t-0
              shadow-lg
            "
          >
            <a
              class="flex items-center h-8 px-3 text-sm hover:bg-gray-200"
              href="#"
              >Item 1</a
            >
            <a
              class="flex items-center h-8 px-3 text-sm hover:bg-gray-200"
              href="#"
              >Item 2</a
            >
          </div>
        </div>
        <div align="left" class="z-5">
          <button
            class="
              btn btn-primary
              text-white
              no-animation
              rounded-lg rounded-l-none
              bg-purple-700
              hover:bg-purple-700
            "
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Search",
});
</script>
