<template>
  <div class="pt-20">
    <nav class="fixed top-0 left-0 z-40 w-full h-16 bg-white shadow-sm">
      <div
        class="
          relative
          z-50
          max-w-screen-xl
          px-2
          mx-auto
          bg-white
          shadow-sm
          sm:px-4
          lg:px-8 lg:shadow-none lg:bg-transparent
        "
      >
        <div class="relative z-50 flex items-center justify-between h-16">
          <div class="flex items-center px-2 lg:px-0">
            <a
              href="/"
              aria-label="Home"
              class="flex items-center flex-shrink-0 nuxt-link-active"
            >
              <img
                src="../assets/icon.png"
                alt="TheSagasu"
                class="w-auto h-8 mr-2 sm:h-10"
              />
              The<b>Sagasu</b>
              <span
                class="
                  bg-black
                  text-white
                  font-semibold
                  ml-2
                  px-2
                  py-0.5
                  rounded-full
                  shadow-inner
                  text-blueGray-500 text-xs
                "
              >
                1.0
              </span>
            </a>
            <div class="hidden lg:block lg:ml-6">
              <div class="flex">
                <NavBarLink href="/docs" text="Docs" />
              </div>
            </div>
          </div>
          <div class="hidden lg:block lg:ml-4">
            <div class="flex items-center">
              <a
                href="https://github.com/TheSagasu/SagasuFrontline"
                target="_blank"
                class="mx-6 text-blueGray-500 hover:text-gray-700"
              >
                <span class="sr-only"> GitHub </span>
                <svg fill="currentColor" viewBox="0 0 24 24" class="w-6 h-6">
                  <path
                    fill-rule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavBarLink from "@/components/NavBarLink.vue";

export default defineComponent({
  name: "NavBar",
  components: {
    NavBarLink,
  },
});
</script>
