<template>
  <div class="flex justify-center items-center">
    <div class="p-4">
      <img src="../assets/icon.png" width="300" height="200" />
    </div>
    <div>
      <h2>
        <span
          class="
            text-4xl
            leading-10
            tracking-tight
            text-gray-800
            sm:text-5xl sm:leading-none
            md:text-6xl
          "
          >The</span
        >
        <span
          class="
            bg-black
            ml-1
            p-2
            rounded-lg
            text-4xl
            font-extrabold
            leading-10
            tracking-tight
            text-white
            sm:text-5xl sm:leading-none
            md:text-6xl
          "
          >Sagasu</span
        >
      </h2>
      <p class="max-w-md mx-auto mt-5 text-base text-gray-500">
        An open, high-performance and community-driven anime dialog search
        engine project
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Hero",
});
</script>
