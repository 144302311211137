<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/icon.png" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
});
</script>
