<template>
  <NavBar />
  <Hero />
  <Search />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Search from "@/components/Search.vue";
import NavBar from "@/components/NavBar.vue";
import Hero from "@/components/Hero.vue";

export default defineComponent({
  name: "App",
  components: {
    NavBar,
    Search,
    Hero,
  },
});
</script>
